<template>
    <v-container fluid>
		<v-row justify="center" class="ma-0">
			<v-col cols="12" xs="12" class="pa-0 mt-2">
				<v-row class="ma-0">
					<v-select v-if="estMembreCourantSuperAdmin" :style="!affichagePortable ? 'max-width: 300px' : ''" :items="listeCentres" label="Niveau" prepend-inner-icon="mdi-fire-truck" outlined dense hide-details item-text="nom" return-object v-model="centre_selectionne"></v-select>
					<v-checkbox :class="!affichagePortable ? 'mx-12 mt-1' : 'mt-3'" color="primary" v-model="toutes_dates" label="Toutes dates" hide-details dense></v-checkbox>
					<v-menu v-model="openDateDebut" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on }">
							<v-text-field outlined dense v-model="date_debut_formatee" readonly :class="!affichagePortable ? 'ml-4' : 'mt-3'" :style="!affichagePortable ? 'max-width: 200px' : ''" label="Date début" prepend-inner-icon="mdi-calendar-month" hide-details :disabled="toutes_dates" v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="date_debut" :max="date_fin" @input="openDateDebut = false" first-day-of-week="1"></v-date-picker>
					</v-menu>
					<v-menu v-model="openDateFin" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on }">
							<v-text-field outlined dense v-model="date_fin_formatee" readonly :class="!affichagePortable ? 'ml-4' : 'mt-3'" :style="!affichagePortable ? 'max-width: 200px' : ''" label="Date fin" prepend-inner-icon="mdi-calendar-month" hide-details :disabled="toutes_dates" v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="date_fin" :min="date_debut" @input="openDateFin = false" first-day-of-week="1"></v-date-picker>
					</v-menu>
					<v-btn :class="!affichagePortable ? 'ml-12' : 'mt-3 ml-4'" small outlined fab color="primary" :loading="chargement_stats" @click="fetchStatsCentre">
						<v-icon>mdi-magnify</v-icon>
					</v-btn>
				</v-row>
				<v-row class="ma-0 mt-12 text-center" justify="center">
					<div class="font-italic font-weight-light">Nombre de gardes attribuées</div>
				</v-row>
				<v-row class="ma-0 mb-12">
					<div id="graphCentreWrapper">
						<bar-chart :chart-data="statsCentre" :options="optionsChart" :styles="styleGraph"></bar-chart>
					</div>
				</v-row>
				<v-row class="ma-0 mt-2">
					<v-autocomplete :style="!affichagePortable ? 'max-width: 300px' : ''" :items="listeMembresTriee" :item-text="texteSelectSPV" label="SPV" prepend-inner-icon="mdi-account" outlined dense hide-details return-object v-model="membre_selectionne"></v-autocomplete>
				</v-row>
				<v-fade-transition mode="out-in">
					<div v-if="this.membre_selectionne" :key="this.membre_selectionne == null ? 0 : 1">
						<v-row class="ma-0 text-center" justify="center">
							<div class="font-italic font-weight-light">Nombre de dispos données et gardes attribuées</div>
						</v-row>
						<v-row class="ma-0">
							<div id="graphMembreWrapper">
								<bar-chart :chart-data="statsMembre" :options="optionsChart" :styles="styleGraphMembre"></bar-chart>
							</div>
						</v-row>
					</div>
				</v-fade-transition>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import axios from 'axios'
	import Moment from 'moment'
	Moment.locale('fr')
	import { extendMoment } from 'moment-range'
	const moment = extendMoment(Moment)
	import BarChart from './../components/BarChart'
    export default {
		components: {
			BarChart
		},
        created() {
			this.$store.commit('majTitrePage', 'Statistiques')
        },
        mounted() {
			//	initialisation du centre
			this.$store.dispatch('getListeCentres').then( () => {
				this.centre_selectionne = this.listeCentres.find( centre => centre.id ==  this.$store.getters.membreCourant.centre.id)
				//this.date_fin = moment().format('YYYY-MM-DD')
				//this.date_debut = moment().subtract(6, 'months').format('YYYY-MM-DD')
			})
			.then( () => {
				this.fetchStatsCentre()
			})
			
        },
        data() {
			return {
				centre_selectionne: null,
				chargement_stats: false,
				date_debut: null,
				date_fin: null,
				membre_selectionne: null,
				nombre_dates_centre: 0,
				nombre_dates_membre: 0,
				openDateDebut: false,
				openDateFin: false,
				optionsChart: {
					responsive: true,
					legend: {
                        display: true
                    },
					maintainAspectRatio: false,
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true,
								stepSize: 1,
								callback: function(value, index, values) {
									return parseInt(value)
								}
							}
						}],
						xAxes: [{
							ticks: {
								//autoSkip: false
							}
						}]
					}
				},
				statsCentre: {
					labels: [],
					datasets: []
				},
				statsMembre: {
					labels: [],
					datasets: []
				},
				toutes_dates: true            
			}
		},
		computed: {
			affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
			},
			centre_courant() {
				return this.$store.getters.membreCourant.centre
			},
			date_debut_formatee() {
				return this.formattageDate(this.date_debut)
			},
			date_fin_formatee() {
				return this.formattageDate(this.date_fin)
			},
			estMembreCourantSuperAdmin() {
                const roles_utilisateur = this.$store.getters.membreCourant.roles
                return roles_utilisateur.find(role => role.name == 'Super admin')
            },
			listeCentres() {
				return this.$store.getters.liste_centres
			},
			listeMembresTriee() {
				//	on trie par ordre alphabetique la liste des membres contenus dans le centre selectionne
				if(!this.centre_selectionne) return []
				return this.centre_selectionne.membres.sort((a,b) => {
					if(a.user.nom.toLowerCase() < b.user.nom.toLowerCase()) {
						return -1
					}
					else if(a.user.nom.toLowerCase() > b.user.nom.toLowerCase()) {
						return 1
					}

					//	ici on a le même nom donc on tri par prénom
					if(a.user.prenom.toLowerCase() < b.user.prenom.toLowerCase()) {
						return -1
					}
					else if(a.user.prenom.toLowerCase() > b.user.prenom.toLowerCase()) {
						return 1
					}

					return 0
					})
			},
			styleGraph() {
				return {
					width: this.nombre_dates_centre *  60 +'px',
					height: '280px',
					position: 'relative'
				}
			},
			styleGraphMembre() {
				return {
					width: this.nombre_dates_membre *  60 +'px',
					height: '250px',
					position: 'relative'
				}
			}
  
		},
		methods: {
			acces_autorise(permissions_requises) {
                // retourne true si l'utilisateur à les permissions listées
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
                    const permissions_utilisateur = this.$store.getters.membreCourant.permissions

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) ) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) ) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
			},
			date_log_formattee(date) {
				if(!date) return ''
				return moment(date).format('DD/MM/YYYY HH:mm:ss')
			},
			fetchStatsCentre() {
				this.chargement_stats = true
				axios.post('api/centre/stats', { id_membre:this.$store.getters.membreCourant.id, id_centre: this.centre_selectionne ? this.centre_selectionne.id : null ,date_debut: this.date_debut, date_fin: this.date_fin, toutes_dates: this.toutes_dates })
                    .then( (reponse) => {
						let data = []
						// méthode abscisse texte
						
						// ajout des dates
						let date_min = null
						let date_max = null
						for (const [creneau, value] of Object.entries(reponse.data.stats)) {
							for (const [date, liste_gardes] of Object.entries(value)) {
								
								// 1ere iteration: on initalise les dates min et max
								if(!date_min && !date_max) {
									date_min = moment(date, 'YYYY MM')
									date_max = moment(date, 'YYYY MM')
								}

								// si date < date min, on met à jour date_min
								if(moment(date, 'YYYY MM').isBefore(date_min)) date_min = moment(date, 'YYYY MM')

								// si date > date max, on met à jour date max
								if(moment(date, 'YYYY MM').isAfter(date_max)) date_max = moment(date, 'YYYY MM')
								
							}
						}

						// initialisation du tableau global de date
						let liste_dates = []
						const range = moment.range(moment(date_min), moment(date_max))
						for (let mois of range.by('month')) {
							liste_dates.push(mois.format('MMM YY'))
						}

						this.nombre_dates_centre = liste_dates.length
						
						// ajout des donnees
						const tableauCouleur = ['#DCEDC8','#E1BEE7', '#F8BBD0', '#D1C4E9', '#FFE0B']
						let indexCouleur = 0
						for (const [creneau, value] of Object.entries(reponse.data.stats)) {
							let data_creneau = new Array(liste_dates.length) // initialisation à 0
							for (const [date, liste_gardes] of Object.entries(value)) {
								
								// recherche de l'index correspondant dans le tableau des dates créés
								const index = liste_dates.findIndex( elem => elem == moment(date, 'YYYY MM').format('MMM YY'))
								data_creneau[index] = liste_gardes.length
							}
							data.push({
								label: creneau,
								data: data_creneau,
								backgroundColor: tableauCouleur[indexCouleur],
								barThickness: 20,
							})
							if(indexCouleur >= tableauCouleur.length - 1) {
								indexCouleur = 0
							}
							else {
								indexCouleur += 1
							}
						}

						this.statsCentre = {
							labels: liste_dates,
							datasets: data
						}
					})
					.then(() => {
						//	on cherche les stats du membre s'il y en a un de sélectionné
						if(this.membre_selectionne) this.fetchStatsMembre()
					})
                    .catch( (error) => {
						if(error.response && error.response.data.error) {
							this.snackbar('error', error.response.data.error)
						}
						else {
							this.snackbar('error', 'Erreur lors du chargement des statistiques')
						}
                    })
                    .then(() => {
						this.chargement_stats = false
                    })
			},
			fetchStatsMembre() {
				this.chargement_stats = true
				axios.post('api/centre/statsMembre', { id_membre:this.$store.getters.membreCourant.id, id_membre_cible: this.membre_selectionne.id ,id_centre: this.centre_selectionne ? this.centre_selectionne.id : null ,date_debut: this.date_debut, date_fin: this.date_fin, toutes_dates: this.toutes_dates })
                    .then( (reponse) => {
						let data = []
						// méthode abscisse texte
						
						// ajout des dates
						let date_min = null
						let date_max = null
						for (const [date, liste_gardes] of Object.entries(reponse.data.stats_gardes)) {
							// 1ere iteration: on initalise les dates min et max
							if(!date_min && !date_max) {
								date_min = moment(date, 'YYYY MM')
								date_max = moment(date, 'YYYY MM')
							}

							// si date < date min, on met à jour date_min
							if(moment(date, 'YYYY MM').isBefore(date_min)) date_min = moment(date, 'YYYY MM')

							// si date > date max, on met à jour date max
							if(moment(date, 'YYYY MM').isAfter(date_min)) date_max = moment(date, 'YYYY MM')
							
						}
						for (const [date, liste_gardes] of Object.entries(reponse.data.stats_dispos)) {
							// 1ere iteration: on initalise les dates min et max
							if(!date_min && !date_max) {
								date_min = moment(date, 'YYYY MM')
								date_max = moment(date, 'YYYY MM')
							}

							// si date < date min, on met à jour date_min
							if(moment(date, 'YYYY MM').isBefore(date_min)) date_min = moment(date, 'YYYY MM')

							// si date > date max, on met à jour date max
							if(moment(date, 'YYYY MM').isAfter(date_max)) date_max = moment(date, 'YYYY MM')
						}

						
						// initialisation du tableau global de date
						let liste_dates = []
						const range = moment.range(moment(date_min), moment(date_max))

						for (let mois of range.by('month')) {
							liste_dates.push(mois.format('MMM YY'))
						}

						this.nombre_dates_membre = liste_dates.length
						
						// ajout des donnees dispos
						let data_dispos = new Array(liste_dates.length) // initialisation à 0
						for (const [date, liste_dispos] of Object.entries(reponse.data.stats_dispos)) {
							// recherche de l'index correspondant dans le tableau des dates créés
							const index = liste_dates.findIndex( elem => elem == moment(date, 'YYYY MM').format('MMM YY'))
							data_dispos[index] = liste_dispos.length
						}

						// ajout des donnees gardes
						let data_gardes = new Array(liste_dates.length) // initialisation à 0
						for (const [date, liste_gardes] of Object.entries(reponse.data.stats_gardes)) {
							// recherche de l'index correspondant dans le tableau des dates créés
							const index = liste_dates.findIndex( elem => elem == moment(date, 'YYYY MM').format('MMM YY'))
							data_gardes[index] = liste_gardes.length
						}
												
						this.statsMembre = {
							labels: liste_dates,
							datasets: [
								{
									label: 'Dispos',
									data: data_dispos,
									backgroundColor: '#FFE0B2',
									barThickness: 20
								},
								{
									label: 'Gardes',
									data: data_gardes,
									backgroundColor: '#B2EBF2',
									barThickness: 20
								}
							]
						}

					})
					.catch( (error) => {
						if(error.response && error.response.data.error) {
							this.snackbar('error', error.response.data.error)
						}
						else {
							this.snackbar('error', 'Erreur lors du chargement des statistiques')
						}
                    })
                    .then(() => {
						this.chargement_stats = false
                    })
			},
			formattageDate(date) {
				if (!date) return null
				return moment(date).format('DD/MM/YYYY')
			},
			getPhoto(chemin){
				return "/storage/"+chemin;
			},
			getRandomInt () {
				return Math.floor(Math.random() * (50 - 5 + 1)) + 5
			},
			premiereLettreMaj(text){
				return text.charAt(0).toUpperCase();
			},
			snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
			},
			texteSelectSPV(item) {
				return item.user.nom+' '+item.user.prenom
			}

        },
        validations: {
            
		},
		watch: {
			centre_courant() {
				
				//  si pas autorisé à rester sur cette page on le vire
                if(!this.acces_autorise('acces statistiques')) {
					this.$router.push('/')
				}
				else {
					this.$store.dispatch('getListeCentres').then( () => {
						this.centre_selectionne = this.listeCentres.find( centre => centre.id ==  this.$store.getters.membreCourant.centre.id)
						//this.date_fin = moment().format('YYYY-MM-DD')
						//this.date_debut = moment().subtract(6, 'months').format('YYYY-MM-DD')
					})
					.then( () => {
						this.fetchStatsCentre()
					})
				}
			},
			centre_selectionne() {
				this.membre_selectionne = null
				this.fetchStatsCentre()
				if(this.estMembreCourantSuperAdmin) {
					this.$store.commit('majTitrePage', 'Statistiques du '+this.centre_selectionne.nom)
				}
			},
			membre_selectionne() {
				this.fetchStatsMembre()
			}
		}
  	}
</script>

<style>

.liste_annuaire {
	overflow-y: auto;
	max-height: calc(100vh - 115px);
	/* min-height: calc(100vh - 115px); */
}
.header_annuaire {
	padding-left: 0px !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	
}

.panel_annuaire  {
	border-style: solid;
	border-width: 1px;
	border-color: rgb(224, 224, 224);
	
}

.v-expansion-panel:before {
  box-shadow: none !important;
  
}

.v-expansion-panel {
	border-top: 0;
}

#graphMembreWrapper, #graphCentreWrapper {
	max-width: 100% !important;
	overflow-x: auto
}

#table_logs {
	width: 100%;
}

#table_logs .v-data-table-header th {
	background-color: #F0F4C3 !important;
}

#table_logs .v-data-table__wrapper {
	background-color: #FAFAFA !important;
}


</style>